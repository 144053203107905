<div class="out" #outComponent [ngClass]="{  'dark-theme': theme=='dark'}">
  <div *ngIf="remote_origin && show != 'in_call_asset'" class="top">
    <div class="top_tx">
      <strong>Call link</strong> {{remote_origin}}
    </div>
    <div class="access" (click)="openRemote()">
      <div *ngIf="!bWaiting" class="abs_center at">
        Request Access
      </div>
      <div *ngIf="bWaiting" class="abs_center at1">
        Waiting ...
      </div>
    </div>
  </div>
  <div class="body" [ngClass]="{body_h:remote_origin && show=='side_by_side'}">
    <app-chat class="app-chat"></app-chat>
    <div class="right" [style.left]="left" [style.width]="width">
      <k-video *ngIf="show=='kvideo'" role="me" class="content" [bMirrow]="true">
      </k-video>
      <in-call-asset class="content" *ngIf="show=='in_call_asset'"></in-call-asset>
      <in-call-caller class="content" *ngIf="show=='in_call_caller'"></in-call-caller>
      <side-by-side class="content" *ngIf="show=='side_by_side'"></side-by-side>

    </div>
  </div>
  <desk-buttons class="menu1" *ngIf="!isPhone"></desk-buttons>
  <phone-buttons class="menu1" *ngIf="isPhone"></phone-buttons>

  <tools class="tools"></tools>

</div>
