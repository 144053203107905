<div class="scrn" #scrn>
  <div class="abs_center out">
    <div class="ti">
      Create new keyzii account
    </div>
    <div class="st">
      First name
    </div>
    <input class="in" type="name" placeholder="First Name" name="" value="" [(ngModel)]="first_name" (ngModelChange)="changedName($event )">
    <div class="st">
      Last Name
    </div><input class="in" type="name" placeholder="Last Name" name="" value="" [(ngModel)]="last_name" (ngModelChange)="changedName($event )">
    <div class="st">
      Company name
    </div>
    <input class="in" type="name" placeholder="Company name" name="" value="" [(ngModel)]="cia_name" (ngModelChange)="changedName($event )">
    <div class="st">
      Email address
    </div><input class="in" type="email" placeholder="Email address " name="" value="" [(ngModel)]="email" (ngModelChange)="changedEmail($event )">
    <div class="create" (click)="addAccount()" [ngClass]="{ready:bReady}">
      <div class="abs_center">
        Create Account
      </div>
    </div>
    <img class="cancel" src="assets/images/close.svg" alt="close" (click)="cancel()">
  </div>
</div>
