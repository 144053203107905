<div class="out">
  <div class="top_line">

    <div class="total_users_div">
      Plan users
      <div class="total_users">
        {{plan_users}}
      </div>
    </div>
    <div class="total_users_div">
      Total users
      <div class="total_users">
        {{total_users}}
      </div>
    </div>
    <div class="total_users_div total_bill">
      Total Monthly Bill
      <div class="total_users">
        {{monthly_bill}}
      </div>
    </div>
  </div>

  <div class="user heading">
    <span class="acc_name">Account Name</span>
    <span class="num_users"> Total Users</span>
    <span class="actions">
      Actions
    </span>
  </div>
  <div *ngFor="let acc of sub_accounts; let i = index" class="line">
    <!-- <div class="">
      {{acc|json}}
    </div> -->
    <span class="acc_name">{{acc.cia_name}}</span>
    <span class="num_users"> {{acc.num_users}}</span>

    <img *ngIf="i!==0" class="edit" src="assets/images/edit_user.svg" alt="" (click)="editAcc(acc)">
    <img *ngIf="i!==0" class="call" src="assets/images/call_user.svg" alt="">
  </div>

  <!-- <new-acc *ngIf="bAddingAccount" class="add_acc" (close)="addAcc($event)"></new-acc> -->
  <!-- <edit-acc *ngIf="edit_account" [account]="edit_account" class="edit_acc" (close)="saveEditiAccount($event)"></edit-acc> -->
</div>
