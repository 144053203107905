import * as store from '@ngrx/store';
/*
The action that have to do with communications
*/
export enum WSSActionTypes {
  //Tools
  LogedIn = '[user]logged_in',
  SessionId = '[sessionon]id',
  Busy = '[bussy]',
  InCall = '[in-call]',
  LeftCall = '[left]call',
  HangUp = '[call]-hangup',
  GotMessage = '[msg]in',
  SessionExpired = '[session]exp',
  RemoteData = '[remote]data'
}
export class RemoteData implements store.Action {
  readonly type = WSSActionTypes.RemoteData;
  constructor(public data: any) { }
}

export class SessionExpired implements store.Action {
  readonly type = WSSActionTypes.SessionExpired;
  constructor() { }
}
export class GotMessage implements store.Action {
  readonly type = WSSActionTypes.GotMessage;
  constructor(public msg: any) { }
}
export class Busy implements store.Action {
  readonly type = WSSActionTypes.Busy;
  constructor(public yes: boolean) { }
}
export class HangUp implements store.Action {
  readonly type = WSSActionTypes.HangUp;
  constructor() { }
}

export class SessionId implements store.Action {
  readonly type = WSSActionTypes.SessionId;
  constructor(public id: string) { }
}

export class LogedIn implements store.Action {
  readonly type = WSSActionTypes.LogedIn;
  constructor(public rv: any) { }
}

export class InCall implements store.Action {
  readonly type = WSSActionTypes.InCall;
  constructor(public call: any) { }
}

export class LeftCall implements store.Action {
  readonly type = WSSActionTypes.LeftCall;
  constructor(public who: string) { }
}
export type WSSAction =
  SessionId
  | RemoteData
  | Busy
  | InCall
  | LeftCall
  | LogedIn
  | HangUp
  | GotMessage
  | SessionExpired
  ;
