/*
Copyright(c) 2023 Sergio A. Fernandez, Ph.D.


  call_waiting notification
  when we get queue,
  if we are NOT in a call
  We check the queue, if an entry is calling
    if the entry is for a SPECIFIC rep IGNORE it
    else notify

  when the call is answer turn the notificaio off


*/

import { PagesAction, PagesActionTypes } from "./pages.actions";
import { PagesState, initialPagesState } from "./pages.model";

function selType(options: any[], pagesState: PagesState) {
  // console.log("sel type")
  let filter_options: any = []
  if (options[0].selected) {
    for (let i = 1; i < options.length; i++) {
      let opt = Object.assign({}, options[i])
      opt.selected = true
      filter_options.push(opt)
    }
  } else {
    for (let i = 1; i < options.length; i++) {
      let opt = options[i]
      if (opt.selected) {
        filter_options.push(opt)
      }
    }
  }

  if (pagesState.on_board) {
    let opt = Object.assign({}, pagesState.on_board)
    opt.selected = true
    filter_options.push(opt)
  }
  pagesState.filter_options = filter_options
}
function foldersForTab(assets: any, bAdmin: boolean, tab_level: number, folders: any[]): any[] {
  if (assets) {
    // tab_level += 5
    let keys = Object.keys(assets)
    for (let i = 0; i < keys.length; i++) {
      let id: string = keys[i]
      let asset = assets[id]
      if (asset.type == 'folder' && asset.bAdmin == bAdmin) {
        // let folder = Object.assign({}, asset)
        // folder.tab_level = tab_level
        folders.push(asset)
        // folders = foldersForTab(folder.assets, bAdmin, tab_level, folders)
      }
    }
  }
  return folders
}
function filterAssets(pagesState: PagesState) {
  if (pagesState.in_page != '/assets') {
    return
  }
  let tab = pagesState.tab;
  let search = pagesState.search
  let assets
  if (pagesState.show_folder) {
    if (pagesState.show_folder.assets) {
      assets = pagesState.show_folder.assets
    } else {
      assets = {}
    }
  } else {
    assets = pagesState.assets
  }

  if (assets) {

    let all_asset_keys = Object.keys(assets)
    let filtered_assets: any = {}

    for (let i = 0; i < all_asset_keys.length; i++) {

      let key = all_asset_keys[i]
      let asset = assets[key]

      let bAddAsset = false
      if (tab == "company") {
        if (asset.bAdmin) {
          bAddAsset = true
        }
      } else if (tab == "agent") {
        if (!asset.bAdmin) {
          bAddAsset = true
        }
      } else if (tab == "all") {
        bAddAsset = true
      }
      let bOnBoardAsset: boolean = false
      if (pagesState.on_board) {
        if (asset.type == "iframe_pro" || asset.type.startsWith("on_board")) {
          if (asset.type != pagesState.on_board.type) {
            bAddAsset = false
          } else {
            bOnBoardAsset = true
          }
        }
      }

      if (!bOnBoardAsset && bAddAsset && pagesState.filter_options) {
        bAddAsset = false;
        for (let o = 0; o < pagesState.filter_options.length; o++) {
          let opt = pagesState.filter_options[o]
          if (opt.type == asset.type || opt.type == "none") {
            bAddAsset = true;
            break;
          } else if (opt.type == "video" && (asset.type == "uploaded" || asset.type == "youtube")) {
            bAddAsset = true;
            break;
          } else if (opt.type == "matterport") {
            if (asset.type == "kuula") {
              bAddAsset = true;
              break;
            }
          }
        }
      }
      if (bAddAsset && search.length > 0) {
        if (asset.name) {
          bAddAsset = (asset.name.indexOf(search) >= 0)
        } else if (asset.type) {
          bAddAsset = (asset.type.indexOf(search) >= 0)
        }
      }
      if (bAddAsset) {
        filtered_assets[key] = asset
      }
    }
    //We got the filter folder, now we need the folders inside these filtered folders to be added to the folders

    pagesState.filtered_assets = filtered_assets
  } else {
    console.error("no assets")
  }
}
/*
function addFolder(assets: any, tab_level: number, folders: any[]): any {
  if (assets) {
    tab_level += 5
    let keys = Object.keys(assets)
    for (let i = 0; i < keys.length; i++) {
      let id: string = keys[i]
      let asset = assets[id]
      if (asset.type == 'folder') {
        let folder = Object.assign({}, asset)
        folder.tab_level = tab_level
        folders.push(folder)
        let rv = addFolder(asset.assets, tab_level, folders)
        if (rv) {
          return rv
        }
      }
    }
  }
  return folders
}*/

function getHeaderPath(folder_id: string, assets: any, path: any[], bAdmin: boolean): any[] | undefined {
  let folder = assets[folder_id]

  if (folder) {
    let new_path = Object.assign([], path)
    new_path.unshift({ name: folder.name, folder_id: folder.asset_id })
    return new_path
  }
  let keys = Object.keys(assets)
  for (let i = 0; i < keys.length; i++) {
    let id: string = keys[i]
    let asset = assets[id]
    if (asset.type == 'folder' && asset.bAdmin == bAdmin) {

      let rv = getHeaderPath(folder_id, asset.assets, path, bAdmin)
      if (rv) {
        let new_path = Object.assign([], rv)
        new_path.unshift({ name: asset.name, folder_id: asset.asset_id })
        return new_path
      }
    }
  }
  return undefined
}

function setHeaderPath(pagesState: PagesState) {
  let path: any[] | undefined = []
  if (pagesState.show_folder) {
    path = getHeaderPath(pagesState.show_folder.asset_id, pagesState.assets, [], pagesState.show_folder.bAdmin)
  }
  if (path) {
    if (pagesState.tab == 'company') {
      path.unshift({ name: "Company Assets" })
    } else if (pagesState.tab == 'agent') {
      path.unshift({ name: "Personal Assets" })
    } else if (pagesState.tab == 'contracts') {
      path.unshift({ name: "Sent Contracts" })
    } else if (pagesState.tab == 'live') {
      path.unshift({ name: "Live Calls" })
    } else if (pagesState.tab == 'leads') {
      path.unshift({ name: "Leads" })
    } else if (pagesState.tab == 'history') {
      path.unshift({ name: "History" })
    } else if (pagesState.tab == 'kpayset') {
      path.unshift({ name: "Payments set up" })
    }
    pagesState.header_path = path
  }
}
/*
function removeFromFolder(folder_id: string, assets: any, target_ids: string[]): any {
  let folder = assets[folder_id]
  if (folder) {
    let new_assets = Object.assign({}, folder.assets)
    let removed_assets: any[] = []
    for (let i = 0; i < target_ids.length; i++) {
      let id = target_ids[i]
      let asset = assets[id]
      removed_assets.push(asset)
      delete new_assets[id]
    }
    let new_folder = Object.assign({}, folder)
    new_folder.assets = new_assets
    let new_keys = Object.keys(new_assets)
    new_folder.num_assets = new_keys.length
    return {
      removed: removed_assets,
      asset: new_folder
    }
  }
  let keys = Object.keys(assets)
  for (let i = 0; i < keys.length; i++) {
    let id: string = keys[i]
    let asset = assets[id]
    if (asset.type == 'folder') {

      let rv = removeFromFolder(folder_id, asset.assets, target_ids)
      if (rv) {
        let new_assets = Object.assign({}, asset.assets)
        new_assets[rv.asset.asset_id] = rv.asset
        asset.assets = new_assets
        return {
          removed: rv.removed_assets,
          asset: asset
        }
      }
    }
  }
  return undefined
}
*/
function addAsset(pagesState: PagesState, new_asset: any): any {
  let asset = Object.assign({}, new_asset)
  asset.bAdmin = pagesState.tab == 'company'
  if (pagesState.show_folder) {
    let assets: any = {}
    if (pagesState.show_folder.assets) {
      assets = Object.assign({}, pagesState.show_folder.assets)
    }
    assets[asset.asset_id] = asset
    let folder = Object.assign({}, pagesState.show_folder)
    folder.assets = assets
    folder.num_assets = Object.keys(assets).length
    pagesState.assets = replaceAsset(pagesState.assets, folder)
    pagesState.show_folder = folder
  } else {
    let assets = Object.assign({}, pagesState.assets)
    assets[asset.asset_id] = asset
    pagesState.assets = assets
  }
}
function findAsset(assets: any, new_asset_id: string): any {
  if (assets) {
    let asset = assets[new_asset_id]
    if (asset) {
      return asset
    }
    let keys = Object.keys(assets)
    for (let i = 0; i < keys.length; i++) {
      let id: string = keys[i]
      let asset = assets[id]
      if (asset.type == 'folder') {
        let target_asset = findAsset(asset.assets, new_asset_id)
        if (target_asset) {

          return target_asset
        }
      }
    }
  }
  return undefined
}

function replaceAsset(assets: any, new_asset: any) {

  let asset = assets[new_asset.asset_id]
  if (asset) {
    let new_assets = Object.assign({}, assets)
    new_assets[new_asset.asset_id] = new_asset
    return new_assets
  }
  let keys = Object.keys(assets)
  for (let i = 0; i < keys.length; i++) {
    let id: string = keys[i]
    let asset = assets[id]
    if (asset.type == 'folder' && asset.bAdmin == new_asset.bAdmin) {
      let new_folder_assets = replaceAsset(asset.assets, new_asset)
      if (new_folder_assets) {
        let new_folder = Object.assign({}, asset)
        new_folder.assets = new_folder_assets
        //now replace the new_foder in the assts
        let new_assets = Object.assign({}, assets)
        new_assets[new_folder.asset_id] = new_folder
        return new_assets
      }
    }
  }
  return undefined
}
function removeAssets(pagesState: PagesState, asset_ids: string[]) {
  let assets
  if (pagesState.show_folder) {
    if (pagesState.show_folder.assets) {
      assets = Object.assign({}, pagesState.show_folder.assets)
    } else {
      assets = {}
    }
  } else {
    assets = Object.assign({}, pagesState.assets)
  }
  let removed_assets: any = {}
  for (let i = 0; i < asset_ids.length; i++) {
    let id = asset_ids[i]
    let asset = assets[id]
    removed_assets[id] = asset
    delete assets[id]

  }
  if (pagesState.show_folder) {
    let folder = Object.assign({}, pagesState.show_folder)
    folder.assets = assets
    folder.num_assets = Object.keys(assets).length
    if (pagesState.show_folder) {
      if (pagesState.show_folder.asset_id == folder.asset_id) {
        pagesState.show_folder = folder
      }
    }
    pagesState.assets = replaceAsset(pagesState.assets, folder)

  } else {
    pagesState.assets = assets
  }
  filterAssets(pagesState)
}
function moveAssets(pagesState: PagesState, asset_ids: string[], target_id: string) {
  let assets
  if (pagesState.show_folder) {
    if (pagesState.show_folder.assets) {
      assets = Object.assign({}, pagesState.show_folder.assets)
    } else {
      assets = {}
    }
  } else {
    assets = Object.assign({}, pagesState.assets)
  }
  let removed_assets: any = {}
  for (let i = 0; i < asset_ids.length; i++) {
    let id = asset_ids[i]
    let asset = assets[id]
    if (asset) {
      if (asset.type != 'folder') {
        removed_assets[id] = asset
        delete assets[id]
      }
    } else {
      console.error("no asset " + id)
    }
  }
  if (pagesState.show_folder) {
    let folder = Object.assign({}, pagesState.show_folder)
    folder.assets = assets
    folder.num_assets = Object.keys(assets).length
    pagesState.assets = replaceAsset(pagesState.assets, folder)
    if (pagesState.show_folder) {
      if (pagesState.show_folder.asset_id == folder.asset_id) {
        pagesState.show_folder = folder
      }
    }
  } else {
    pagesState.assets = assets
  }

  //find the target
  if (target_id == "company" || target_id == "agent") {
    let assets = Object.assign({}, pagesState.assets)
    let bAdmin = target_id == "company"
    for (let i = 0; i < asset_ids.length; i++) {
      let id = asset_ids[i]
      let asset = Object.assign({}, removed_assets[id])
      asset.bAdmin = bAdmin
      assets[id] = asset
    }
    pagesState.assets = assets
  } else {
    let target = findAsset(pagesState.assets, target_id)

    let target_folder = Object.assign({}, target)//pagesState.filtered_folders[target_index])
    let assets = Object.assign({}, target_folder.assets)
    let bAdmin = target_folder.bAdmin
    for (let i = 0; i < asset_ids.length; i++) {
      let id = asset_ids[i]
      let asset = Object.assign({}, removed_assets[id])
      asset.bAdmin = bAdmin
      assets[id] = asset
    }
    target_folder.assets = assets
    target_folder.num_assets = Object.keys(assets).length
    if (pagesState.show_folder) {
      if (pagesState.show_folder.asset_id == target_folder.asset_id) {
        pagesState.show_folder = target_folder
      }
    }
    pagesState.assets = replaceAsset(pagesState.assets, target_folder)
  }
  filterAssets(pagesState)
}
//******************************************************************************
// pagesReducer
//******************************************************************************
export function pagesReducer(state = initialPagesState,
  action: PagesAction): PagesState {
  switch (action.type) {

    case PagesActionTypes.EditResellerAcc: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.editing_reseller_acc = action.acc
      return pagesState;
    }
    case PagesActionTypes.Reseller: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.reseller = action.reseller
      return pagesState;
    }
    case PagesActionTypes.SiteReady: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.site_ready = true
      return pagesState;
    }
    case PagesActionTypes.OnBoarding: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.on_boarding = action.on_boarding
      return pagesState;
    }
    case PagesActionTypes.ShowActiveCalls: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.num_active_calls = action.num_calls
      return pagesState;
    }

    case PagesActionTypes.AccOverdue: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.acc_overdue = action.overdue
      return pagesState;
    }
    case PagesActionTypes.ShowToolAddAsset: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.show_tool_add_asset = action.show
      return pagesState;
    }

    case PagesActionTypes.GotInvoices: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.invoices = action.invoices
      return pagesState;
    }
    case PagesActionTypes.ShowCC: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.show_cc = action.show
      return pagesState;
    }
    case PagesActionTypes.AskHangUp: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.ask_hangup = action.ask
      return pagesState;
    }
    case PagesActionTypes.PlayRec: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.play_rec = action.play_rec
      return pagesState;
    }
    case PagesActionTypes.CodeFromURL: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.code_from_url = action.code_from_url
      return pagesState;
    }

    // case PagesActionTypes.AskCodeFrom: {
    //   const pagesState: PagesState = Object.assign({}, state);
    //   pagesState.ask_code_from = action.ask_code_from
    //   return pagesState;
    // }

    case PagesActionTypes.ApiUrlPArams: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.api_url_params = action.api_url_params
      return pagesState;
    }
    case PagesActionTypes.CloseProgress: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.close_progress++
      return pagesState;
    }

    case PagesActionTypes.InPhone: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.in_phone = action.who
      return pagesState;
    }

    case PagesActionTypes.ShowWelcome: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.welcome = action.who
      return pagesState;
    }
    case PagesActionTypes.UpdateAsset: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.assets = replaceAsset(pagesState.assets, action.asset)
      if (action.asset.type == 'folder') {
        setHeaderPath(pagesState)
      }
      return pagesState;
    }


    case PagesActionTypes.SetFoldersForTab: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.filtered_folders = foldersForTab(pagesState.assets, action.bAdmin, 0, []) //Its not doing anything
      return pagesState;
    }
    case PagesActionTypes.SetTab: {
      const pagesState: PagesState = Object.assign({}, state);
      if (pagesState.tab != action.tab) {
        pagesState.tab = action.tab
        pagesState.show_folder = undefined
        setHeaderPath(pagesState)
        filterAssets(pagesState)
        let bAdmin = pagesState.tab == 'company'
        pagesState.filtered_folders = foldersForTab(pagesState.assets, bAdmin, 0, []) //Its not doing anything

      }
      return pagesState;
    }
    case PagesActionTypes.SearchBy: {
      const pagesState: PagesState = Object.assign({}, state);
      if (pagesState.search != action.search) {
        pagesState.search = action.search
      }
      filterAssets(pagesState)
      return pagesState;
    }

    case PagesActionTypes.AddAsset: {
      const pagesState: PagesState = Object.assign({}, state);
      addAsset(pagesState, action.asset)
      filterAssets(pagesState)
      if (action.asset && action.asset.asset_id) {
        pagesState.save_assets = pagesState.assets
      }
      return pagesState;
    }
    case PagesActionTypes.SetSelAssets: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.selected_assets = action.asset_ids
      return pagesState;
    }
    case PagesActionTypes.RemoveAssets: {
      const pagesState: PagesState = Object.assign({}, state);
      removeAssets(pagesState, pagesState.selected_assets)
      pagesState.save_assets = pagesState.assets

      return pagesState;
    }
    case PagesActionTypes.MoveAssets: {
      const pagesState: PagesState = Object.assign({}, state);
      moveAssets(pagesState, action.asset_ids, action.target_id)
      pagesState.save_assets = pagesState.assets

      return pagesState;
    }
    case PagesActionTypes.UnselectAllAssets: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.unselect_all++
      return pagesState;
    }
    case PagesActionTypes.FilterAssets: {
      const pagesState: PagesState = Object.assign({}, state);
      filterAssets(pagesState)
      return pagesState;
    }
    case PagesActionTypes.SelFilterOption: {
      const pagesState: PagesState = Object.assign({}, state);
      selType(action.filter_options, pagesState)
      return pagesState;
    }
    case PagesActionTypes.OpenMenu: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.open_menu = action.open
      return pagesState;
    }
    case PagesActionTypes.CallerIsPhone: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.caller_is_phone = action.is_phone
      return pagesState;
    }

    case PagesActionTypes.NotifyError: {
      const pagesState: PagesState = Object.assign({}, state);
      if (action.text) {
        pagesState.notify_usr = {
          text: action.text,
          icon: "notify_error"
        }
      } else {
        pagesState.notify_usr = undefined
      }
      return pagesState;
    }

    case PagesActionTypes.NotifyUser: {
      const pagesState: PagesState = Object.assign({}, state);
      if (action.text) {
        pagesState.notify_usr = {
          text: action.text,
          icon: "notify_arrow"
        }
      } else {
        pagesState.notify_usr = undefined
      }
      return pagesState;
    }
    case PagesActionTypes.NotifyWait: {
      const pagesState: PagesState = Object.assign({}, state);
      if (action.text) {
        pagesState.notify_usr = {
          text: action.text,
          icon: "notify_wait"
        }
      } else {
        pagesState.notify_usr = undefined
      }
      return pagesState;
    }
    case PagesActionTypes.ShowError: {
      const pagesState: PagesState = Object.assign({}, state);
      if (action.text) {
        pagesState.notify_usr = {
          text: action.text,
          icon: "stop_showing",
          show: true
        }
      } else {
        pagesState.notify_usr = undefined
      }
      return pagesState;
    }


    case PagesActionTypes.NotifySnd: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.notify_snd = action.on
      return pagesState;
    }

    case PagesActionTypes.Debuggin: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.debug = action.debug
      return pagesState;
    }
    case PagesActionTypes.StartRecording: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.rec_status = "starting"
      return pagesState;
    }
    case PagesActionTypes.Recording: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.rec_status = "recording"
      return pagesState;
    }
    case PagesActionTypes.StopRecording: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.rec_status = "stopping"
      return pagesState;
    }
    case PagesActionTypes.RecordingReady: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.rec_status = "ready"
      return pagesState;
    }
    case PagesActionTypes.SetManagerName: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.manager_name = action.name
      return pagesState;
    }
    case PagesActionTypes.SetManagerEmail: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.manager_email = action.email
      return pagesState;
    }
    case PagesActionTypes.SetRepName: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.rep_name = action.name
      return pagesState;
    }
    case PagesActionTypes.SetRepEmail: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.rep_email = action.email
      return pagesState;
    }

    case PagesActionTypes.IAmManager: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.i_am_manager = action.manager
      return pagesState;
    }


    case PagesActionTypes.ShowAsset: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.show_asset = action.asset
      if (action.asset) {
        pagesState.on_boarding = false
      }
      return pagesState;
    }

    case PagesActionTypes.ShowFolder: {
      const pagesState: PagesState = Object.assign({}, state);
      let path: any[] | undefined = []
      if (action.folder_id) {
        let folder = findAsset(pagesState.assets, action.folder_id)
        pagesState.show_folder = folder
        let bAdmin = pagesState.tab == "company"
      } else {
        pagesState.show_folder = undefined
      }
      setHeaderPath(pagesState)



      filterAssets(pagesState)
      return pagesState;
    }

    case PagesActionTypes.UserRoll: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.my_roll = action.roll
      return pagesState;
    }

    case PagesActionTypes.GotAccount: {
      console.log("GotAccount")
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.users = action.account.users
      pagesState.reps = action.account.reps
      pagesState.customize_account = action.account.customize
      pagesState.cia_qr_img = action.account.cia_qr_img
      pagesState.rep_qr_img = action.account.rep_qr_img
      pagesState.acc_created_at = action.account.created_at
      if (action.account.package && action.account.package.features) {
        pagesState.has_api = action.account.package.features.api
        if (action.account.package.features.dealer) {
          pagesState.is_dealer = true
        }
        if (action.account.package.features.on_board_wnd) {
          pagesState.on_board = { desc: "Onboarding Pro", type: "on_board_wnd" }
        } else if (action.account.package.features.on_board_pro) {
          pagesState.on_board = { desc: "Onboarding Pro", type: "on_board_pro" }
        } else if (action.account.package.features.on_board_p2p) {
          pagesState.on_board = { desc: "Onboarding Pro", type: "on_board_p2p" }
        } else if (action.account.package.features.iframe_pro) {
          pagesState.on_board = { desc: "Onboarding Light", type: "iframe_pro" }
        }
        if (action.account.package.features.site) {
          pagesState.site_enabled = true
        }


        if (action.account.package.urls) {
          pagesState.on_board_urls = action.account.package.urls
        }

        // ,
        // ,
        // , { desc: "Onboarding Pro", type: "on_board_p2p" }
        // , { desc: "Onboarding Pro", type: "on_board_wnd" }



        // pagesState.on_board = (
        //   action.account.package.features.iframe_pro
        //   || action.account.package.features.on_board_pro
        //   || action.account.package.features.on_board_p2p
        //   || action.account.package.features.iframe_pro
        // )
      }
      if (pagesState.on_board) {
        filterAssets(pagesState)
      }
      console.log("pagesState.on_board " + JSON.stringify(pagesState.on_board))
      console.log("pagesState.on_board_urls " + JSON.stringify(pagesState.on_board_urls))
      pagesState.api_params = action.account.api_params

      return pagesState;
    }
    case PagesActionTypes.SetAPIParams: {
      // console.log("GotAccount")
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.api_params = action.api_params

      return pagesState;
    }
    case PagesActionTypes.ShowAPI: {
      console.log("ShowAPI")
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.show_api = action.show
      return pagesState;
    }


    case PagesActionTypes.SetCustomizaton: {
      // console.log("GotAccount")
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.customize_account = action.customize
      return pagesState;
    }

    case PagesActionTypes.GotContracts: {
      console.log("GotContracts")
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.contracts = action.rv
      return pagesState;
    }

    case PagesActionTypes.GotUsers: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.users = action.account.users
      pagesState.reps = action.account.reps
      return pagesState;
    }

    case PagesActionTypes.ActiveCalls: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.active_calls = action.calls
      return pagesState;
    }
    case PagesActionTypes.SetAssets: {
      const pagesState: PagesState = Object.assign({}, state);
      let prev_keys_len = Object.keys(pagesState.assets).length
      pagesState.assets = action.assets

      // //TO OPEN AN ASSETS Sep 5, 24
      // let keys = Object.keys(pagesState.assets)
      // let key = keys[5]
      // pagesState.show_asset = pagesState.assets[key]


      if (prev_keys_len == 0) {
        let new_keys_len = Object.keys(pagesState.assets).length
        if (new_keys_len > 0) {
          let bAdmin = pagesState.tab == 'company'
          pagesState.filtered_folders = foldersForTab(pagesState.assets, bAdmin, 0, []) //Its not doing anything
        }
      }
      // pagesState.asset_to_filter = action.assets
      return pagesState;
    }
    case PagesActionTypes.SetAssetsAndContracts: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.assets_and_contracts = action.assets
      return pagesState;
    }


    case PagesActionTypes.CallerData: {
      const pagesState: PagesState = Object.assign({}, state);
      if (action.data.caller_first_name) {
        pagesState.caller_first_name = action.data.caller_first_name
      }
      if (action.data.caller_last_name) {
        pagesState.caller_last_name = action.data.caller_last_name
      }
      if (action.data.caller_cia_name) {
        pagesState.caller_cia_name = action.data.caller_cia_name
      }
      if (action.data.caller_email) {
        pagesState.caller_email = action.data.caller_email
        pagesState.contact_info = "ok"
      }
      if (action.data.rep_name) {
        pagesState.rep_name = action.data.rep_name
        pagesState.my_name = action.data.rep_name
      }
      if (action.data.rep_last_name) {
        pagesState.my_last_name = action.data.rep_last_name
      }
      if (action.data.rep_email) {
        pagesState.rep_email = action.data.rep_email
      }

      return pagesState;
    }
    case PagesActionTypes.ContactInfo: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.contact_info = action.state
      return pagesState;
    }

    // case PagesActionTypes.CallerEmail: {
    //   const pagesState: PagesState = Object.assign({}, state);
    //   pagesState.caller_email = action.email
    //   pagesState.contact_info = "ok"
    //   return pagesState;
    // }


    case PagesActionTypes.IsPhone: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.is_phone = action.yes
      return pagesState;
    }
    case PagesActionTypes.CleanOnHangUp: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.caller_first_name = ""
      pagesState.caller_last_name = ""
      pagesState.caller_cia_name = ""
      pagesState.caller_email = ""
      pagesState.rep_name = ""
      pagesState.rep_email = ""
      pagesState.manager_name = ""
      pagesState.manager_email = ""
      pagesState.in_phone = undefined
      pagesState.show_cc = undefined
      pagesState.rec_status = "ready";
      pagesState.show_asset = undefined;
      pagesState.search = "";
      pagesState.site_ready = false;
      return pagesState;
    }
    /*
    accepted_eula_on
    bShowing24
    email
    host_url
    roll
    url
    work_time
    _id - wss
      customer_id - wss
    */
    case PagesActionTypes.GotMyPicture: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.my_picture = action.picture
      return pagesState;
    }
    case PagesActionTypes.SetCalendlyRep: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.calendly_rep = action.calendly
      return pagesState;
    }
    case PagesActionTypes.GotLoginData: {
      console.log("GotLoginData")
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.accepted_eula_on = action.data.accepted_eula_on
      if (action.data.host_url) {
        pagesState.host_url = action.data.host_url
      }
      if (action.data.url) {
        pagesState.rep_url = action.data.url
      }
      if (action.data.work_time) {
        pagesState.work_time = action.data.work_time
      }
      pagesState.calendly_rep = action.data.calendly
      pagesState.my_picture = action.data.picture
      pagesState.my_email = action.data.email
      pagesState.my_name = action.data.name
      pagesState.my_last_name = action.data.last_name
      pagesState.my_company = action.data.company
      pagesState.my_company_title = action.data.company_title
      pagesState.my_sms = action.data.sms
      pagesState.my_company_name = action.data.company_name
      pagesState.rep_link = action.data.rep_link
      if (action.data.has_google_calendar) {
        pagesState.has_google_calendar = true
      }
      pagesState.converge = action.data.converge

      if (action.data.converge_acc) {
        if (action.data.bIsVenditio) {
          pagesState.converge_acc = "keyzii"
        } else {
          pagesState.converge_acc = "pay"
        }
      }
      pagesState.merchant_id = action.data.merchant_id

      pagesState.plans = action.data.plans
      pagesState.code = action.data.code
      if (pagesState.code && !pagesState.code.users) {
        console.error("!this.code.users")
      }
      pagesState.invoices = action.data.invoices
      pagesState.bill_info = action.data.bill_info
      if (action.data.package && action.data.package.features) {
        if (action.data.package.features.api) {
          pagesState.has_api = true
        }
        if (action.data.package.features.dealer) {
          pagesState.is_dealer = true
        }
      }
      return pagesState;
    }
    case PagesActionTypes.GotBillInfo: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.bill_info = action.bill_info
      return pagesState;
    }
    case PagesActionTypes.GotPlans: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.plans = action.plans
      return pagesState;
    }
    case PagesActionTypes.GotCode: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.code = action.code
      if (pagesState.code && !pagesState.code.users) {
        console.error("!this.code.users")
      }
      return pagesState;
    }
    case PagesActionTypes.GotConverge: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.converge = action.converge
      return pagesState;
    }
    /*
    let entry = {
      queue_id: i,
      caller_name: socket.caller_name,
      caller_email: socket.caller_email,
      caller_id: socket.caller_id,
      rep_id: socket.rep_id,
      state: socket.state,
      start_calling_at: socket.start_calling_at
    }
    */

    case PagesActionTypes.RemoveUserProfPic: {
      const pagesState: PagesState = Object.assign({}, state);
      delete pagesState.my_picture
      return pagesState;
    }
    case PagesActionTypes.CallWaiting: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.call_waiting = action.call_waiting
      return pagesState;
    }
    case PagesActionTypes.RejectedCall: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.call_rejected = action.call_rejected
      pagesState.call_waiting = undefined
      return pagesState;
    }

    case PagesActionTypes.GotQueue: {
      const pagesState: PagesState = Object.assign({}, state);
      pagesState.queue = action.queue
      return pagesState;
    }

    default: {
      return state;
    }
  }
}
