import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { DealerService } from 'src/app/kpay/dealer.service';
import { ShowCC } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { editingResellerAcc, hostUrl } from 'src/app/pages/pages.selectors';

@Component({
  selector: 'edit-acc',
  templateUrl: './edit-acc.component.html',
  styleUrls: ['./edit-acc.component.scss']
})
export class EditAccComponent {
  search: string = ""
  num_users: number = 1
  bInvalidUsers: boolean = false
  bShowStatus: boolean = false
  expand_index: number = -1
  exp_user: any
  usage: any
  phone: string = ""
  picture: string | undefined
  users_keys: string[] = []
  _account: any
  logo: string | undefined
  subscriptions: any[] = []
  host_url: string | undefined

  // @Output() close: EventEmitter<any> = new EventEmitter();
  constructor(
    private dealer_service: DealerService,
    private pagesState: Store<PagesState>,

  ) {
    console.log("edit sub acc")
    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      this.host_url = url
    }))
    this.subscriptions.push(this.pagesState.select(editingResellerAcc).subscribe((acc: any) => {
      if (acc) {
        this.getSubAccount(acc.customer_id)
      }
    }))

  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  async  getSubAccount(sub_acc_id: string) {
    this._account = await this.dealer_service.getSubAccont(sub_acc_id)
    if (this._account) {
      if (this._account.customize) {
        this.logo = this._account.customize.logo
      }
      this.users_keys = Object.keys(this._account.users)
    }
  }

  cancel() {
    this.pagesState.dispatch(new ShowCC(undefined))
  }
  filterName($event: string) {

  }
  changeUsers($event: number) {

  }
  async toggleDetails(i: number) {
    if (this.expand_index == -1) {
      this.expand_index = i
      try {
        let user_id = this.users_keys[i]
        this.exp_user = this._account.users[user_id]
        this.usage = await this.dealer_service.getUsage(user_id)
        let user: any = await this.dealer_service.getSubUser(user_id)
        if (user) {
          if (user.phone) {
            this.phone = user.phone
          }
          else {
            this.phone = "n/a"
          }
          if (user.picture)
            this.picture = user.picture
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      this.expand_index = -1
    }
  }
  call(user_key: string) {
    if (this.host_url) {
      let protocol = "https://"
      if (this.host_url.indexOf("localhost") >= 0) {
        protocol = "http://"
      }
      let link = protocol + this.host_url + "?rep_id=" + user_key
      window.open(link, '_blank', 'location=yes,height=800,width=1200,scrollbars=yes,status=yes');
    }
  }
}
