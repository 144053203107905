import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { DealerService } from 'src/app/kpay/dealer.service';
import { ShowCC } from 'src/app/pages/pages.actions';
import { PagesState } from 'src/app/pages/pages.model';
import { validateEmail } from 'src/app/utils';

@Component({
  selector: 'new-acc',
  templateUrl: './new-acc.component.html',
  styleUrls: ['./new-acc.component.scss']
})
export class NewAccComponent {
  bReady: boolean = false
  first_name: string = "Uno"
  last_name: string = "Primero"
  cia_name: string = "first"
  email: string = "uno@first.com"
  bInvalidEmail: boolean = true


  // @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    public pagesState: Store<PagesState>,
    private dealer_service: DealerService
  ) {
    this.changedEmail(this.email)
  }
  changedName($event?: any) {
    this.bReady = (
      this.first_name.length > 0
      && this.last_name.length > 0
      && this.cia_name.length > 0
      && !this.bInvalidEmail
    )
  }
  cancel() {
    this.pagesState.dispatch(new ShowCC(undefined))
  }
  addAccount() {
    // this.close.emit(
    let acc = {
      first_name: this.first_name,
      last_name: this.last_name,
      cia_name: this.cia_name,
      email: this.email
    }
    this.dealer_service.newAccount(acc)
    this.pagesState.dispatch(new ShowCC(undefined))
    // )
  }
  changedEmail($event: any) {
    if ($event) {
      if (validateEmail($event)) {
        this.email = $event
        this.bInvalidEmail = false
      } else {
        this.bInvalidEmail = true
      }
      this.changedName()
    }
  }
}
