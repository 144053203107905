import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { NotifyError, Reseller } from "../pages/pages.actions";
import { PagesState } from "../pages/pages.model";

@Injectable({
  providedIn: 'root'
})
export class DealerService {
  constructor(
    private httpClient: HttpClient,
    private pagesState: Store<PagesState>,

  ) {
    console.log("reseller service")
  }
  getSubAcconts() {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let rv = await this.httpClient.get('/reseller').toPromise();
        this.pagesState.dispatch(new Reseller(rv))
        resolve()
      } catch (e: any) {
        this.pagesState.dispatch(new NotifyError(e.error.error))
        reject(e.error);
      }
    })
  }
  getSubAccont(id: string) {
    return new Promise<any>(async (resolve, reject) => {
      try {
        let rv = await this.httpClient.get('/sub_account/' + id).toPromise();
        resolve(rv)
      } catch (e: any) {
        this.pagesState.dispatch(new NotifyError(e.error.error))
        reject(e.error);
      }
    })
  }

  newAccount(new_account: any) {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let rv = await this.httpClient.post('/reseller', new_account).toPromise();
        this.pagesState.dispatch(new Reseller(rv))
        // rv.num_sub_users
        // rv.sub_accounts


        resolve()
      } catch (e: any) {
        this.pagesState.dispatch(new NotifyError(e.error.error))
        reject(e.error);
      }
    })
  }
  async getUsage(user_id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = '/usage/' + user_id
        let usage: any = await this.httpClient.get(url).toPromise();
        resolve(usage)
      } catch (e) {
        reject(e)
        console.error("Error usage " + JSON.stringify(e))
      }
    })
  }
  async getSubUser(user_id: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = '/sub_user/' + user_id
        let usage: any = await this.httpClient.get(url).toPromise();
        resolve(usage)
      } catch (e) {
        reject(e)
        console.error("Error sub-user " + JSON.stringify(e))
      }
    })
  }
}
