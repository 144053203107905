import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { SelectToolOpts } from 'src/app/canvas/canvas.actions';
import { ToolService } from 'src/app/tools/tool.service';
import { asset_types, getMaterportId, getUrlExtension, getYouTubeId, paramsFromURl, } from 'src/app/utils';
import { AddAsset, NotifyError, NotifyUser, ShowToolAddAsset } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { onBoard, onBoardURLs, showAsset, showFolder } from '../../pages.selectors';

import { UploaderService } from '../../uploader.service';


@Component({
  selector: 'new-asset',
  templateUrl: './new-asset.component.html',
  styleUrls: ['./new-asset.component.scss']
})
export class NewAssetComponent {
  bShowSpinner: boolean = false;
  bUploading: boolean = false;
  bError: boolean = false;
  bEnableSave: boolean = false;
  bShowUpload: boolean = false
  link_place_holder: string = "Link or upload file"
  save_back: string = "#d9d9d9"
  bShowClear: boolean = false;
  bShowUploaded: boolean = true;
  bShowOpt: boolean = false;
  bBadLink: boolean = false;
  bUsekzLib: boolean = false;

  @Input() bAdmin: boolean = true;
  options: any[] = []

  asset_type: any = {}

  link: string = ""
  name: string = ""
  file_name: string = ""
  uploader: FileUploader | undefined
  accept: string | undefined
  mat_url: any

  folder: any
  pending_key: string | undefined //If we dont' save the asset use this to remove image from s3
  // @Output() close: EventEmitter<void> = new EventEmitter();


  asset: any | undefined

  asset_desc: string = "Select asset type"
  error: string | undefined
  subscriptions: any[] = []
  on_board: any
  bShowDragAndDrop: boolean = true

  urls: any[] = []
  bShowURLS: boolean = false
  url_index: number = 0;

  @ViewChild('file') file: ElementRef | undefined;
  @ViewChild('mfile') mfile: ElementRef | undefined;
  @ViewChild('link_fld') link_fld: ElementRef | undefined;

  constructor(
    public sanitizer: DomSanitizer,
    public upload_service: UploaderService,
    private tools_service: ToolService,
    public pagesState: Store<PagesState>,
    public canvasStore: Store<CanvasState>,
    // public recaptcha_service: RecaptchaService
  ) {
    this.setOptions()
    this.pagesState.select(onBoardURLs).subscribe((on_board_urls: any[]) => {
      this.urls = on_board_urls
    })
    this.subscriptions.push(this.pagesState.select(onBoard).subscribe((on_board: any) => {
      this.on_board = on_board
      this.setOptions()
    }))
    console.log("new asset")
    this.subscriptions.push(this.pagesState.select(showFolder).subscribe((folder: any) => {
      if (folder) {
        if (folder.asset_id || folder._id) {
          this.folder = folder
        } else {
          this.folder = undefined
        }
      }
    }))

  }
  setOptions() {
    // let opts = Object.assign([], asset_types)
    let options: any[] = []
    let from = 0
    if (asset_types[0].desc == "All") {
      options.push(asset_types[0])
      from = 1
    }
    if (this.on_board) {
      options.push(this.on_board)
    }
    for (let i = from; i < asset_types.length; i++) {
      let opt = Object.assign({}, asset_types[i])
      options.push(opt)
    }
    this.options = options

  }
  async  triggerFile() {
    if (this.file) {
      try {
        // await this.recaptcha_service.checkCaptcha("load_img")
        this.file.nativeElement.click();
      } catch (e: any) {
        this.pagesState.dispatch(new NotifyError(e))
      }
    }

  }
  async  triggerMFile() {
    if (this.mfile) {
      try {
        // await this.recaptcha_service.checkCaptcha("load_img")
        this.mfile.nativeElement.click();
      } catch (e: any) {
        this.pagesState.dispatch(new NotifyError(e))
      }
    }

  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
    if (this.pending_key) {
      this.tools_service.removeKeys([this.pending_key])
    }
  }
  ngAfterViewInit() {
    // this.uploader = this.upload_service.setUpUploader(this.asset_type, this.bIsRep)
    this.selType(this.asset_type)
  }
  selType(i: number) {
    let opt = this.options[i]

    this.selType1(i)
    this.link = ""
    this.save_back = "#d9d9d9"

  }
  selType1(i: number) {
    return new Promise<MediaStream | void>(async (resolve, reject) => {

      setTimeout(() => {
        this.bShowOpt = false
        this.bShowUploaded = false;
        this.bShowSpinner = false;
        this.bEnableSave = false;
        this.bShowUpload = false
        this.link_place_holder = "Link or upload file"
        this.bBadLink = false

        this.bShowClear = false;


        let bShowUpload = false
        let opt: any = this.options[i]
        if (opt) {
          this.asset_type = opt
          this.asset_desc = opt.desc
          if (opt.type == "image") {
            bShowUpload = true;
            this.accept = "image/x-png,image/gif,image/jpeg,image/svg+xml"
          } else if (opt.type == "video") {
            bShowUpload = true;
            this.accept = "video/mp4,video/quicktime,video/x-ms-wmv"
          } else if (opt.type == "matterport") {
          } else if (opt.type == "pdf" || opt.type == "contract") {
            bShowUpload = true;
            this.accept = "application/pdf"
          }
          if (opt.type == "training") {
            this.save_back = "#080808"
          }

          this.bShowURLS = (opt.type == 'iframe_pro' || opt.type.startsWith("on_board"))
          if (this.bShowURLS && this.urls.length > 0) {
            this.save_back = "#080808"
          }
          let link_place_holder: string = ""
          if (bShowUpload) {
            link_place_holder = "Link or upload file"
            this.uploader = this.upload_service.setUpUploader(opt.type, !this.bAdmin)
            this.uploader.onCompleteItem = (item: FileItem, rsponse: string, status: number, _header: any) => {
              if (status == 200) {
                setTimeout(() => {
                  this.link_place_holder = ""
                  this.bShowUploaded = true
                  this.bShowClear = true
                  this.bEnableSave = true;
                  this.bShowSpinner = false;
                  this.bShowUpload = false
                  this.save_back = "#080808"

                  if (item.file && item.file.name) {
                    this.file_name = item.file.name
                  }

                  if (this.name.length == 0 && this.file_name) {
                    this.name = this.file_name
                  }
                  let jres = JSON.parse(rsponse)
                  this.pending_key = jres.key
                  if (opt.type == "image") {
                    this.asset = {
                      type: "image",
                      images: [{
                        "key": jres.key,
                        "url": jres.url,
                        "exp": jres.exp
                      }]
                    }
                  } else if (opt.type == "video") {
                    this.asset = {
                      type: "uploaded",
                      "key": jres.key,
                      "url": jres.url,
                      "exp": jres.exp
                    }
                  }
                  // else if (opt.type  == "Contracts") {
                  //   this.asset = {
                  //     type: "contracts",
                  //     "key": jres.key,
                  //   }
                  // }
                  else if (opt.type == "pdf" || opt.type == "contract") {
                    this.asset = {
                      type: opt.type,
                      "key": jres.key,
                      "url": jres.url,
                      "exp": jres.exp
                    }
                  }
                })
              } else {
                this.bError = true
              }
            }
          } else {

            link_place_holder = this.asset_type.desc + " link"
          }

          this.link_place_holder = link_place_holder
          this.bShowUpload = bShowUpload
          this.bEnableSave = (!bShowUpload)
          if (this.link_fld) {
            let disabled = false

            this.link_fld.nativeElement.disabled = disabled;

          }
        }
        resolve()
      })
    })
  }
  closeURLS() {
    if (this.urls[this.url_index]) {
      this.link = this.urls[this.url_index]
    }
    this.bShowURLS = false
    setTimeout(() => {
      if (this.link_fld) {
        this.link_fld.nativeElement.disabled = true;
      }
    }, 10)
  }
  /*
  asset_id: "1674153429230"
bAdmin: false
name: "1014"
type: "matterport"
url: "JbaCzgR5oDg"
  */
  makeMatterport() {
    return new Promise<MediaStream | void>(async (resolve, reject) => {
      let mp_id = getMaterportId(this.link)
      this.asset = {
        // name: "1014"
        type: "matterport",
        url: mp_id
      }

      //use the sdk to pull the name
      if (this.asset.url.length > 10) {
        let url = "https://my.matterport.com/show?m=" + this.asset.url + "&title=0&qs=1&hr=0&brand=0&help=0&play=1&dh=0&fp=0"
        this.mat_url = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        setTimeout(async () => {
          try {
            let me = this;
            let iframe = <HTMLIFrameElement>document.getElementById("sdk-iframe");
            const applicationKey: string = "h2pcf8ax0kwwmugtepwtbdaeb"
            let sdk = await (window as any).MP_SDK.connect(iframe, applicationKey, '3.4');
            let modelDetails = await sdk.Model.getDetails()
            console.log('Model name:' + modelDetails.name);
            me.asset.name = modelDetails.name
            me.name = modelDetails.name
            me.bEnableSave = true;
            resolve()
          } catch (e: any) {
            console.error(e)
            reject()
          }
        }, )
      }
    })
  }
  async save() {
    if (this.save_back == "#d9d9d9") {
      return
    }
    if (!this.asset && this.asset_type.type == "training") {
      this.asset = {
        type: this.asset_type.type,
      }
    }
    if (this.asset_type.type == "image"
      || this.asset_type.type == "iframe") {
      this.asset = {
        type: this.asset_type.type,
        "url": this.link
      }
    } else if (this.urls[this.url_index]) {
      if (this.asset_type.type == "iframe_pro"
        || this.asset_type.type == "on_board_pro"
        || this.asset_type.type == "on_board_p2p"
        || this.asset_type.type == "on_board_wnd") {
        this.asset = {
          type: this.asset_type.type,
          "url": this.urls[this.url_index],
          kzLib: this.bUsekzLib
        }
      }
    } else if (this.asset_type.type == "image"
      || this.asset_type.type == "iframe") {
      this.asset = {
        type: this.asset_type.type,
        "url": this.link
      }
    }


    if (this.asset_type.type == "video") {
      let video_id = getYouTubeId(this.link)
      if (video_id) {
        this.asset = {
          type: "youtube",
          "data": video_id,
        }
        console.log("video id " + video_id)
      } else {
        this.tools_service.addS3(this.asset)
      }
    }

    //this.bAdmin means that the user is an admin and can add assets to the admin tab
    if (this.asset && !this.bShowSpinner) {
      this.asset.name = this.name

      try {
        await this.tools_service.changeAsset(this.asset)
      }
      catch (e: any) {
      }
      this.pending_key = undefined
      this.pagesState.dispatch(new ShowToolAddAsset(false))
      this.canvasStore.dispatch(new SelectToolOpts(""))

      // this.close.emit() //needed in assets
    }
  }
  cancel() {
    this.pagesState.dispatch(new ShowToolAddAsset(false))
    this.canvasStore.dispatch(new SelectToolOpts("close_new_asset"))

    // this.close.emit()
  }
  selectedMultipleUploadImage(_evt: any) {
    this.onFileDropped(_evt.currentTarget.files)
  }
  selectedUploadImage(_evt: any) {

    this.queued_files = []
    this.file_name = ""
    if (_evt.currentTarget.files.length == 1) {
      this.file_name = _evt.currentTarget.files[0].name
    }

    this.bUploading = true
    console.log("Selected to upload")
    this.bShowSpinner = true;
    this.link_place_holder = "Uploading file..."
    this.save_back = "#d9d9d9"
    if (this.uploader) {
      this.uploader.uploadAll();
    }
  }
  clear() {
    this.link = ""
    this.bShowClear = false
    this.bShowUploaded = false;
    this.bShowUpload = true
    this.name = ""
    this.save_back = "#d9d9d9"
    if (this.asset) {
      let asset = {
        type: this.asset.type
      }
      this.asset = asset
    }
  }
  /*
  getVideoLink() {

    var video_id: string | undefined
    let parts1: string[] = this.link.split('v=')
    if (parts1.length > 1) {
      video_id = parts1[1]
      let parts2 = video_id.split('&')
      if (parts2) {
        let vid1 = parts2[0]
        if (vid1) {
          video_id = vid1
        }
      } //https://youtu.be/aXYmxYePCes?si=K_vJXwYzQfckPE6v
    } else if (this.link.indexOf('youtu.be') >= 0) { // https://youtu.be/me19SUmWu2s
      parts1 = this.link.split('youtu.be/')
      if (parts1.length > 1) {

        let params = parts1[1].split('?')
        video_id = params[0]
      }
      //  https://www.youtube.com/live/UEJdOaIMgPo?feature=shared
    } else if (this.link.indexOf('https://www.youtube.com/') >= 0) {
      let parts1: string[] = this.link.split('/')
      if (parts1.length > 0) {
        let last = parts1[parts1.length - 1]//UEJdOaIMgPo?feature=shared
        let params = last.split('?')
        video_id = params[0]
      }
    }
    if (video_id) {
      this.asset = {
        type: "youtube",
        "data": video_id,
      }
      console.log("video id " + video_id)
      return video_id;
    }

    return undefined
  }*/

  linkChanged($event: any) {

    setTimeout(async () => {
      if (this.link.length > 0) {
        this.bShowClear = true
        if (this.link.startsWith("https://kuula.co")) {
          await this.selType1(4)
          this.asset_type = { desc: "Virtual Tour", type: "kuula" }
        } else if (this.link.startsWith('https://youtu.be') || this.link.startsWith('https://www.youtube.com/')) {
          let youtube_id = getYouTubeId(this.link)
          if (youtube_id) {
            try {
              this.name = await this.tools_service.getYouTubeTitle(youtube_id)
            } catch (e) {
              console.error(e)
            }
          }
          await this.selType1(6)
        } else if (this.link.startsWith("https://my.matterport.com")) {
          await this.selType1(4)
        } else {
          let ext = getUrlExtension(this.link)
          if (ext) {
            if (ext == "svg" || ext == "png" || ext == "jpg") {
              await this.selType1(5)
            }
          }
        }
        this.bShowClear = true
        this.bShowUpload = false
      } else {
        this.bShowClear = false
        this.bShowUpload = true
      }

      if (this.asset_type.type == "kuula") {
        this.bBadLink = false
        this.asset = {
          // name: "1014"
          type: "kuula",
          url: this.link
        }
      } else if (this.link.startsWith("https://my.matterport.com") || this.asset_type.type == "matterport") {
        let mp_id = getMaterportId(this.link)
        this.bBadLink = (mp_id == undefined)
        if (!this.name && !this.bBadLink) {
          try {
            await this.makeMatterport()
          } catch (e: any) {
            return;
          }
        } else {
          this.asset = {
            // name: "1014"
            type: "matterport",
            url: mp_id
          }
        }

      } else if (this.asset_type.type == "video") {
        this.bBadLink = (getYouTubeId(this.link) == undefined)
      } else {
        try {
          const url = new URL(this.link);
          this.bBadLink = false
        } catch (TypeError) {
          this.bBadLink = true
        }

        // this.bBadLink = (this.link.indexOf("http") < 0)
      }
      if (this.bBadLink) {

        this.save_back = "#d9d9d9"
      } else {

        this.save_back = "#080808"
      }
      console.log("save1 " + this.save_back)
    })
    //check the link if ok, change the save back color
  }
  //****************************************************************************
  // Drop Assets
  //****************************************************************************
  uploading_number: number = 0
  upload_btn_txt = "Close"
  bShowSpin: boolean = false
  files: any = []
  queued_files: any = []

  bHover: boolean = false

  onFileOver($event: any) {
    this.bHover = true
  }
  onFileLeave($event: any) {
    this.bHover = false

  }
  async   onFileDropped($event: any) {

    try {
      // await this.recaptcha_service.checkCaptcha("drop_imgs")
      this.files = []
      this.queued_files = []
      for (let i = 0; i < $event.length; i++) {
        let file = $event[i]
        let d_file: any =
        {
          name: file.name,

        }
        d_file.index = i
        if (file.type == "image/jpeg"
          || file.type == "image/png") {
          d_file.status = "waiting"
          this.queued_files.push(file)
        } else {
          d_file.status = "error"
          d_file.error = "not an image"
        }
        this.files.push(d_file)
      }
      //now upload each one of the files and add them to the floder
      this.uploadFiles()
    } catch (e: any) {
      this.pagesState.dispatch(new NotifyError(e))
    }
  }


  uploadFiles() {
    if (this.files.length > 0) {
      this.uploading_number = 1

      this.bShowSpin = true
      let body = this.upload_service.getUploaderBody('image', this.bAdmin)
      this.uploader = new FileUploader(body)
      this.uploader.clearQueue()
      this.uploader.addToQueue(this.queued_files)
      this.uploader.onCompleteItem = onCompleteImage
      this.uploader.uploadAll()

    }
    let me = this
    function setFileStatus(item: FileItem, status: string) {
      let file_name
      if (item.file && item.file.name) {
        file_name = item.file.name
      }
      for (let i = 0; i < me.files.length; i++) {
        let d_file = me.files[i]
        if (d_file.name == file_name) {
          d_file.status = status
        }
      }
    }
    async function onCompleteImage(item: FileItem, rsponse: string, status: number, _header: any) {
      if (status == 200) {

        setFileStatus(item, "uploaded")

        let jres = JSON.parse(rsponse)
        let asset = {
          type: "image",
          images: [{
            "key": jres.key,
            "url": jres.url,
            "exp": jres.exp
          }]

        }
        try {
          await me.tools_service.changeAsset(asset)
          me.uploading_number++
          if (me.uploading_number >= me.queued_files.length) {
            me.upload_btn_txt = "Done"
          }
        }
        catch (e: any) {
        }
      } else {
        setFileStatus(item, "error")
      }
    }
  }
  cancelUpload() {
    if (this.uploader) {
      this.uploader.cancelAll()
      this.uploading_number = 0
      for (let i = 0; i < this.files.length; i++) {
        let d_file = this.files[i]
        if (d_file.status == "waiting") {
          d_file.status = "canceled"
          this.upload_btn_txt = "Done"
        }
      }
    }
  }
  stopProp($event: any) {
    $event.stopPropagation()
  }
  showTypes($event: any) {
    $event.stopPropagation()
    this.bShowOpt = !this.bShowOpt
  }
  clickOutside($event: any) {
    if ($event.currentTarget.id == "out" && this.bShowOpt) {
      this.bShowOpt = false
    }

  }


}
