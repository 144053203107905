<div class="out">
  <div class="container">
    <div class="content">

      <div *ngIf="site_enabled" class="top_row">
        <div class="section lsect">
          <div class="tx font_title w700 tl">
            <span>Co-widget</span> <span class="help_i" kzLib (click)="openCoHelp()">(?)</span>
          </div>
          <div class="info1 tx font_text noback tl fs14 no_h">
            Co-widget is a smart widget that enables instant video calls directly in your experience (App, wesbite), allowing you to connect, demonstrate, onboard, or support customers without them ever leaving the page.  This seamless technology
            can
            be integrated into applications and various digital experiences, simplifying onboarding and customer support.
          </div>
        </div>
        <div class="section ">
          <div class="bk_gray">
            <div class="tx  font_title w700">
              <span>Company Script</span>
            </div>
            <div class="info1 tx font_text">
              <span>Copy & paste company script. This script allows your visitors to call or book via widget with all users in your company.</span>
            </div>
          </div>
          <div class="sbtn" kzLib (click)="embedCia()">
            <div class="abs_center">Copy Script</div>
          </div>

        </div>

        <div class="section">
          <div class="bk_gray">
            <div class="tx  font_title w700">
              <span>Personal Script</span>
            </div>

            <div class="info1 font_text">
              <span>Copy & paste personal script. This script allows your visitors to call or book via widget with just you personally.</span>
            </div>
          </div>
          <div class="per_btn sbtn" kzLib (click)="embedPersonal()">
            <div class="abs_center">Copy Script</div>
          </div>

        </div>
      </div>

      <div class="li_sep"> </div>
      <div *ngIf="site_enabled" class="top_row">
        <div class="section lsect">
          <div class="tx  font_title w700 tl">
            <span>Embed keyzii iFrame</span>
          </div>
          <div class="info1 tx font_text noback tl fs14">
            Embed keyzii iFrame inside your website or landing page to allow users to call or book directly from your website. This means your customers don’t have to leave to a new tab and can have the full keyzii call experience inside your
            website.
          </div>
        </div>
        <div class="section">
          <div class="bk_gray">
            <div class="tx font_title w700">
              <span>Company iFrame</span>
            </div>
            <div class="info1 tx font_text">
              <span>Embedded company iFrame will let people know when all users inside the your company are online or offline.</span>
            </div>
          </div>
          <div class="sbtn" kzLib (click)="copyCiaiFrame()">
            <div class="abs_center">Copy iFrame</div>
          </div>

        </div>

        <div class="section">
          <div class="bk_gray">
            <div class="tx  font_title w700">
              <span>Personal iFrame</span>
            </div>

            <div class="info1 font_text">
              <div> Embedded personal iFrame will let people know when you are online or offline.</div>
            </div>
          </div>
          <div class="per_btn sbtn" kzLib (click)="copyPeriFrame()">
            <div class="abs_center">Copy iFrame</div>
          </div>

        </div>
      </div>
      <div class="li_sep"> </div>
      <div *ngIf="site_enabled" class="top_row">
        <div class="section lsect">
          <div class="tx  font_title w700 tl">
            <span>Advance asset sharing</span>
          </div>
          <div class="info1 tx font_text noback tl fs14">
            Access and share assets from your website or data base inside your keyzii call without having to pre-upload all your assets.
          </div>
        </div>
        <div class="section">
          <div class="tx  font_title  tl mb20 fs14">
            <span class="w700">Step 1 </span>- Embed iFrame
          </div>
          <div class="bk_gray">
            <div class="tx  font_title">
              <span class="w700">Keyzii API Script</span>
            </div>


            <div class="info1 tx font_text">
              <span>Copy and insert keyzii API script and embed it into your website that you want to access assets. </span>
            </div>
          </div>
          <div class="sbtn mb20" kzLib (click)="copyCiaiFrame()">
            <div class="abs_center">Copy API Script</div>
          </div>
          <div class="ds">


            <div class="tx  font_title  tl mb20 fs14">
              <span class="w700 fs14">Step 2 </span>- Inset URL
            </div>
            <div class="font_text fs14">
              In the input field below, paste the URL of the webpage with assets you wish to share.
            </div>
            <input kzInput class="in" type="text" placeholder="Insert url here" name="" value="" [(ngModel)]="api_iframe_url" (ngModelChange)="changeURL($event)">

            <div class="tx  font_title  tl mb20 fs14">
              <span class="w700 ">Step 3 </span>- Choose Display Options
            </div>
            <div class="font_text fs14">
              In the input field below, paste the URL of the webpage with assets you wish to share. </div>
          </div>
          <div class="line flex">
            <switch class="switch" [checked]="show_in=='wnd'" kzLib (click)="show_in='wnd'"></switch> Inside keyzii call
          </div>
          <div class="line flex">
            <switch class="switch" [checked]="show_in=='tools'" kzLib (click)="show_in='tools'"></switch>Asset side bar
          </div>
          <div class="line flex">
            <switch class="switch" [checked]="show_in=='browser'" kzLib (click)="show_in='browser'"></switch>Separate Window
          </div>


        </div>

      </div>

      <!-- <div class="spt">
        <span class="bld">Embed keyzii in your website</span>
      </div>


      <div class="line flex">
        <div class="kzfr">
          keyzii frame
        </div>


        <div class="code">
          1 {{iframe_example}}
        </div>
        <img class="copy" src="assets/images/copy.svg" kzLib (click)="copyFrame($event)">
      </div>

      <div class="spt2">
        <span class="bld ">Advance asset sharing</span>
      </div>
      <div class="line section"> <span class="bld">1. Follow the steps in the API documentation.</span> <a href="https://www.keyzii.com/apidocs" target="_blank" rel="noopener noreferrer">Click here </a>
      </div>
      <div class="line flex">
        <div class="scrpt">
          keyzii API script
        </div>
        <div class="code">
           {{script_url}}
        </div>
        <img class="copy" src="assets/images/copy.svg" kzLib (click)="copy($event)">

      </div>

      <div class="line section"><span class="bld">2. Enter your URL: </span>In the input field below, paste the URL of the webpage with assets you wish to share.
        <input kzInput class="in" type="text" placeholder="Insert url here" name="" value="" [(ngModel)]="api_iframe_url" (ngModelChange)="changeURL($event)">

      </div>
      <div class="line section"><span class="bld">3. API display options: </span>
      </div>

      <div class="line flex">
        Show API in separate browser window <switch class="switch" [checked]="show_in=='browser'" kzLib (click)="show_in='browser'"></switch>
      </div>
      <div class="line flex">
        Show API in keyzii window
        <switch class="switch" [checked]="show_in=='wnd'" kzLib (click)="show_in='wnd'"></switch> <span class="sm">(make sure the site can run in an iFrame)
        </span>
      </div>
      <div class="line flex">
        Show API in keyzii side bar
        <switch class="switch" [checked]="show_in=='tools'" kzLib (click)="show_in='tools'"></switch><span class="sm">(make sure the site can run in an iFrame) </span>
      </div>
      <div class="line indent section">
        <span class="bld ">4. Go Live:</span> Once on a call, go to the asset side bar, click on the icon <img class="icon" src="assets/images/api_icon.svg" alt=""> to present your API window to search the asset and share from your entered URL
        above.
      </div>

      <div class="line bld support">Support and Resources</div>
      <div class="line mw740">
        For detailed instructions and tips on how to optimize your live presentations, visit skool.com/keyzii or reach out to our customer support.
      </div>
      <div class="end">

      </div> -->
    </div>
  </div>

  <div *ngIf="bShowHelp" class="help">

    <h1 id="kzmconductor">kzmConductor</h1>
    <p>The conductor allows the synchronization of 2 web sites
      It&#39;s almost like been there and grabbing the mouse or the keyboard from the other person</p>
    <p>To install it you go to the advance settings and get the script to add kzmConductor</p>
    <p><code>&lt;script src=&quot;http://localhost:4206/assets/api/site/kzmConductor.js&quot; id=&quot;kz&quot; customer_id=&quot;6692df35c53600249985507e&quot; host=&quot;localhost:4206&quot;&gt; &lt;/script&gt;</code></p>
    <h3 id="button">Button</h3>
    <p>The script adds a floating dynamic button (in the lower left corner ) to the site. If a rep is available &quot;Call Now&quot; is shown in the button, &quot;Book a call&quot; is shown if there is no rep available.</p>
    <h3 id="no-button">No button</h3>
    <p>To tell the script that it should not add a button use add the directive <strong>no_btn=&quot;true&quot; </strong></p>
    <h4 id="updating-the-button">Updating the Button</h4>
    <p>If you want to update the button you can find out if there is somebody on line with the following RESFULL calls</p>
    <p>To find out if there are reps available to answer the phone the call use
      <code>GET /num_reps/:customer_id</code>
      To find out if an specific rep is available to answer use
      <code>GET /on_line/:rep_id</code></p>
    <h4 id="starting-the-call-or-scheduling">Starting the call or scheduling</h4>
    <p>When you want to call or schedule you can call the javascript function</p>
    <p><code>kzConn.click()</code></p>
    <h2 id="setting-the-asset">Setting the asset</h2>
    <p>To be able to see what the user is doing in the site. An asset with the URL of the site where the script is embedded is created. The asset type is <strong>site</strong></p>
    <p>When the rep wants to see the what the user is doing in the web site, it will open the asset</p>
    <h3 id="iframe">iFrame</h3>
    <p>An instance of the remote site (where the user is calling from) runs in an iframe. </p>
    <p>Both instances are synchronized and the rep and the client can see the mouse movements, draw, click and type</p>
    <h3 id="aps-that-do-not-run-in-iframe">Aps that do not run in iFrame</h3>
    <p>If you app does&#39;t run in iframe you can set the header<br><code>Content-Security-Policy: frame-ancestors &#39;self&#39; https://my.keyzii.com;</code></p>
    <p>When the header is not set, the application will not not run in an iframe and after 2 seconds my.keyzii.com will launch an instance of the remote app in its own window. </p>
    <p>Both instances are synchronized and the rep and the client can see the mouse movements, draw, click and type</p>
    <h2 id="applications-that-login">Applications that login</h2>
    <p>An example of an application that logs in is the keyzii rep, when the user ask for support it calls
      <code>kzConn.click(session_id)</code></p>
    <p>The session_id is the session between the keyzii rep app in the caller computer and the back end. When the instance of the application runs in the rep computer, the session_id is used to auto-login the rep.</p>
    <p>Applications that want to support logged user need to auto-login the application running in the rep computer with the user session, the session_id can be any data that will facilitate the process.</p>
    <p>The <strong>session_id</strong> included in the url in the iframe used to launch the app in rep, applications that have the <strong>session_id</strong> need to auto-login</p>
    <p>The following diagram explains the connections, when keyzii is supporting an app with autologin</p>
    <p>------------------------&gt;acme.com (1)<br>| my.keyzii.com (3) &lt;-------&gt; call.keyzii.com (2)<br>|----&gt;acme.com (4)</p>
    <p>(1) is the instance of acme that requires support and includes the <strong>kzmConductor</strong> script<br>(2) is the call.keyzii.com instance inside (1)<br>(3) my.keyzii.com talking to (2)<br>(4) is the instance of acme that is embedded in
      with
      the session_id parameter (3) and is talking to (1)</p>
    <p>When (4) launches, it uses the session_id to auto auto-login</p>

  </div>
  <img *ngIf="bShowHelp" class="close" src="assets/images/close.svg" alt="close" kzLib (click)="bShowHelp=false">

</div>
