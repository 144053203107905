import { Component, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { ChatState } from 'src/app/chat/chat.model';
import { openChat } from 'src/app/chat/chat.selectors';
import { KVideoState } from 'src/app/k-video/k-video.model';
import { checkVideo, streams } from 'src/app/k-video/k-video.selectors';
import { WSSState } from 'src/app/wss/wss.model';
import { callerId, callId, lastMsg, managerId, myId, remoteData, repId, sessionId } from 'src/app/wss/wss.selectors';
import { PagesState } from '../../pages.model';
import { isPhone, myName, notifyUsr, onBoarding, showAPI, showAsset } from '../../pages.selectors';
import { ResizedCanvas } from 'src/app/canvas/canvas.actions';
import { CanvasState } from 'src/app/canvas/canvas.model';
import { CanvasService } from 'src/app/canvas/canvas.service';
import { WSSService } from 'src/app/wss/wss.service';


@Component({
  selector: 'app-in-call',
  templateUrl: './in-call.component.html',
  styleUrls: ['./in-call.component.scss'],
})

export class InCallComponent {
  isPhone: boolean = false
  my_id: string | undefined
  call_id: string | undefined
  caller_id: string | undefined
  rep_id: string | undefined
  manager_id: string | undefined
  showing: string = "no-call" //no assets only rep and caller
  asset: any | undefined
  share_stream: MediaStream | undefined
  bSharingScr: boolean = false
  bLandscape: boolean = true;

  //For the split screen
  left_role: string = "caller"
  right_role: string = "rep"
  circle_role: string = "manager"


  theme: string = 'light';

  bShowTools: boolean = true
  // arrownd_asset: any;

  kwidth: number = 640
  max_width: number = 10000

  action: any = {} //The buttons will update us, we might go gor MVC
  isOpen: boolean = false;

  left = "300px"
  width = "calc(100% - 300px)"

  bShowProgress: boolean = false

  @ViewChild('outComponent') out!: ElementRef;
  @ViewChild('shareElement') shareElement: ElementRef | undefined;
  subscriptions: any[] = []
  bShowAPI: boolean = false
  show: string = "kvideo"
  bOnBoarding: boolean = false
  bShareRemoteScr: boolean = false
  bLocalShare: boolean = false

  bShowAsset: boolean = false
  bShowVideoPen: boolean = false
  bShowIFrameCanvas: boolean = false

  remote_origin: string = ""
  my_name: string | undefined
  session_id: string | undefined
  bWaiting: boolean = false
  constructor(
    private pagesState: Store<PagesState>,
    private wssState: Store<WSSState>,
    private kVideoState: Store<KVideoState>,
    private chatStore: Store<ChatState>,
    public canvasStore: Store<CanvasState>,
    public canvas_service: CanvasService,
    private wss_service: WSSService,
  ) {
    console.log("in-calll")
    this.subscriptions.push(this.wssState.select(sessionId).subscribe((session_id: string | undefined) => {
      this.session_id = session_id

    }))
    this.subscriptions.push(this.pagesState.select(myName).subscribe((name: string | undefined) => {
      this.my_name = name
    }))
    this.subscriptions.push(this.wssState.select(remoteData).subscribe((data: any) => {
      if (data) {
        this.remote_origin = data.remote_origin
      }
    }))
    this.subscriptions.push(this.pagesState.select(onBoarding).subscribe((on_boarding: boolean) => {
      this.bOnBoarding = on_boarding
      this.setVidePenOrIFrame()
    }))
    this.subscriptions.push(this.pagesState.select(showAPI).subscribe((show: boolean) => {
      this.bShowAPI = show
      this.setPage()
      this.setVidePenOrIFrame()
    }))
    this.subscriptions.push(this.chatStore.select(openChat).subscribe((bOpen: boolean) => {
      console.log("open the chat " + bOpen)
      this.isOpen = bOpen
      if (this.isOpen) {
        this.left = "312px"
        this.width = "calc(100% - 312px)"
      } else {
        this.left = "0px"
        this.width = "calc(100% - 0px)"
      }
      this.canvasStore.dispatch(new ResizedCanvas())
    }))

    this.subscriptions.push(this.pagesState.select(isPhone).subscribe((isPhone: boolean) => {
      this.isPhone = isPhone
      this.setShowProgress()
    }))

    this.subscriptions.push(this.pagesState.select(showAsset).subscribe((asset: any) => {
      this.bWaiting = false
      if (asset) {

        if (asset.asset_id || asset._id) {
          this.asset = asset
          this.setShowProgress()
          this.setPage()
          this.setVidePenOrIFrame()
          return;
        }
      }
      delete this.asset
      this.setPage()

    }))

    this.subscriptions.push(this.wssState.select(myId).subscribe((my_id: string | undefined) => {
      this.my_id = my_id
      if (!this.call_id) {
        this.rep_id = this.my_id
        this.bWaiting = false
      }
      this.setPage()
    }))
    this.subscriptions.push(this.wssState.select(callId).subscribe((call_id: string | undefined) => {
      if (this.call_id && !call_id) {
        this.remote_origin = ""
      }
      this.call_id = call_id

      this.setShowProgress()
      this.setPage()
    }))
    this.subscriptions.push(this.wssState.select(callerId).subscribe((_id: string | undefined) => {
      this.caller_id = _id
      this.setPage()
    }))
    this.subscriptions.push(this.wssState.select(repId).subscribe((_id: string | undefined) => {
      this.rep_id = _id
      if (!this.rep_id && !this.call_id) {
        this.rep_id = this.my_id
      }
      this.setPage()
    }))
    this.subscriptions.push(this.wssState.select(managerId).subscribe((_id: string | undefined) => {
      this.manager_id = _id
      this.setPage()
    }))
    this.subscriptions.push(this.kVideoState.select(checkVideo).subscribe((check: any | undefined) => {
      if (check) {
        if (check.role == "local_share" || check.role == "remote_share" && this.share_stream) {
          delete this.share_stream
          this.bSharingScr = false
          this.setPage()
        }
      }
    }))
    this.pagesState.select(notifyUsr).subscribe((notify: any | undefined) => {
      if (this.bWaiting && notify.icon == "notify_error") {
        this.bWaiting = false
      }
    })
    this.subscriptions.push(this.kVideoState.select(streams).subscribe((streams: any) => {
      // console.log("k-video  stream for " + JSON.stringify(Object.keys(streams)))
      let share_stream = streams["local_share"]
      if (!share_stream) {
        this.bLocalShare = false
        share_stream = streams["remote_share"]
        if (share_stream) {
          this.bShareRemoteScr = true;
        } else {
          this.bShareRemoteScr = false;
        }
      } else {
        this.bLocalShare = true
        this.bShareRemoteScr = false;
      }
      this.setVidePenOrIFrame()


      if (share_stream) {
        this.bSharingScr = true
        if (!this.share_stream) {
          this.canvas_service.setAsset({ type: "video_canvas", asset_id: "video_canvas1", name: "Videoboard", roll: "share" })
          this.share_stream = share_stream //This will remove the share element

        }
      } else {
        delete this.share_stream
        this.bSharingScr = false
      }
      this.setPage()
    }))

  }
  setVidePenOrIFrame() {
    setTimeout(() => {
      if (this.bLocalShare) {
        this.bShowVideoPen = true
        this.bShowIFrameCanvas = false
      } else if (this.bShareRemoteScr) {
        if (this.bOnBoarding) {
          this.bShowIFrameCanvas = true
          this.bShowVideoPen = false
        } else {
          this.bShowVideoPen = true
          this.bShowIFrameCanvas = false
        }
      }
      if (!this.bShowVideoPen && !this.bShowIFrameCanvas) {
        this.bShowAsset = true
      }
    })
  }
  ngOnDestroy() {
    if (this.shareElement) {
      this.shareElement.nativeElement.srcObject = undefined
    }
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  setShowProgress() {
    if (this.isPhone) {
      this.bShowProgress = false;
    } else {
      if (this.asset) {
        if (this.asset.type == "contract") {
          if (this.call_id) {
            this.bShowProgress = true
          } else {
            this.bShowProgress = false
          }
        }
      }
    }
  }
  setShowing() {


    if (this.asset) {
      this.showing = 'asset';
      return;
    }

    if (!this.call_id) {
      this.showing = 'no-call';
      return;
    }

    if (this.isPhone && this.caller_id) {
      this.showing = 'caller';
      return;
    }

    if (this.caller_id && this.rep_id && this.manager_id) {
      this.showing = 'side-by-side'; //with manager
      //If you are the rep you go in circle_rol and the manager goes in righ_role
      if (this.my_id == this.rep_id) {
        this.right_role = "manager"
        this.circle_role = "rep"
      } else {
        this.right_role = "rep"
        this.circle_role = "manager"
      }
      return;
    }


    this.showing = 'side-by-side';
    if (this.caller_id) {
      this.left_role = "caller"
    } else {
      this.left_role = "manager"
    }
    if (this.rep_id) {
      this.right_role = "rep"
    } else if (this.manager_id) {
      this.right_role = "manager"
    }

  }

  setPage() {
    setTimeout(() => {
      this.setShowing()
      if (this.asset || this.share_stream || this.bShowAPI) {
        this.show = "in_call_asset"
        return
      }

      if (this.showing == 'no-call') {
        this.show = "kvideo"
      } else if (this.showing == 'caller') {
        this.show = "in_call_caller"
      } else if (this.showing == 'side-by-side') {
        this.show = "side_by_side"
      }



    })
  }
  openRemote() {
    this.bWaiting = true
    let name = "The Rep"
    if (this.my_name) {
      name = this.my_name
    }
    let jmsgx = {
      "mcaller": "open_remote",
      name: name,
      rep_id: this.rep_id,
      caller_id: this.caller_id,
      rsid: this.session_id //This is how the other side will find the instance in the iframe
    }
    this.wss_service.sendMessage(jmsgx)
    // this.wss_service.sendMessageToOtherMembers({ open_remote: name })
  }
}
