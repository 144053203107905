import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { DealerService } from 'src/app/kpay/dealer.service';
import { EditResellerAcc, ShowCC } from '../../pages.actions';
import { PagesState } from '../../pages.model';
import { gotPlans, gotReps, gotReseller, gotUsers, hostUrl } from '../../pages.selectors';

@Component({
  selector: 'app-dealer',
  templateUrl: './dealer.component.html',
  styleUrls: ['./dealer.component.scss']
})
export class DealerComponent {
  // bAddingAccount: boolean = false
  // edit_account: any
  edit_user_top: number = 0; //!=0 shows
  edit_user_left: number = 0
  del_user: any
  link_user_top: number = 0; //!=0 shows
  link_user_left: number = 0;
  link_user: any
  del_user_top: number = 0; //!=0 shows
  del_user_left: number = 0;
  show_rolls_index: number = -1
  sel_roll_type: string | undefined
  edit_user: any | undefined
  subscriptions: any[] = []
  host_url: string | undefined
  // @Input() set add_account(n: number) {
  //   if (n) {
  //     this.bAddingAccount = true
  //   }
  // }



  total_users: number = 0
  plan_users: number = 0
  monthly_bill: number = 0
  num_sub_users: number = 0
  sub_accounts: any[] = []
  selected_plan: any

  users: any = {}
  reps: any = {}
  min_users: number = 0

  constructor(
    private pagesState: Store<PagesState>,
    private dealer_service: DealerService
  ) {
    console.log("dealer")
    this.subscriptions.push(this.pagesState.select(gotPlans).subscribe((plans: any[]) => {
      if (plans.length > 0) {
        this.selected_plan = plans[0]
        this.plan_users = this.selected_plan.num_users
        this.monthly_bill = this.selected_plan.amount
      }
    }))

    this.subscriptions.push(this.pagesState.select(gotReseller).subscribe((info: any) => {
      if (info) {
        this.total_users = info.tot_users
        this.num_sub_users = info.num_sub_users

        let sub_accounts = Object.assign([], info.sub_accounts)
        sub_accounts.unshift(info.acc)
        this.sub_accounts = sub_accounts
        // this.monthly_bill = 80 //1st
        // if (this.total_users == 0) {
        //   this.monthly_bill = 0
        // } else if (this.total_users >= 5) {
        //   this.monthly_bill = 50 * 5 + 30 * (this.total_users - 5)
        // } else if (this.total_users == 4) {
        //   this.monthly_bill = 220
        // } else {
        //   this.monthly_bill = 80 + 50 * (this.total_users - 1)
        // }
      }
    }))
    this.subscriptions.push(this.pagesState.select(gotReps).subscribe((users: any[]) => {
      this.users = users
      this.setMinUsers()
    }))
    this.subscriptions.push(this.pagesState.select(gotUsers).subscribe((reps: any[]) => {
      this.reps = reps
      this.setMinUsers()
    }))
    this.subscriptions.push(this.pagesState.select(hostUrl).subscribe((url: string | undefined) => {
      this.host_url = url
    }))
  }
  ngAfterViewInit() {
    this.dealer_service.getSubAcconts()
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    })
  }
  // addAcc($event: any) {
  //   this.dealer_service.newAccount($event)
  //   this.bAddingAccount = false
  // }
  // saveEditiAccount($event: any) {
  //   this.edit_account = undefined
  // }
  editAcc(acc: any) {
    this.pagesState.dispatch(new ShowCC("editacc"))
    this.pagesState.dispatch(new EditResellerAcc(acc))
  }
  setMinUsers() {
    let num_users = 0
    if (this.users) {
      let uk = Object.keys(this.users)
      num_users += uk.length
    }
    if (this.reps) {
      let rk = Object.keys(this.reps)
      num_users += rk.length
    }
    this.min_users = num_users
    if (this.total_users == 0) {
      this.total_users = num_users
    }
  }
  call(user_key: string) {
    if (this.host_url) {
      let protocol = "https://"
      if (this.host_url.indexOf("localhost") >= 0) {
        protocol = "http://"
      }
      let link = protocol + this.host_url + "?rep_id=" + user_key
      window.open(link, '_blank', 'location=yes,height=800,width=1200,scrollbars=yes,status=yes');
    }
  }
}
