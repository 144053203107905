<div class="scrn">
  <div class="abs_center out" id="out" kzLib (click)="clickOutside($event)">
    <div class="title ttop"> New Asset</div>

    <!-- <div *ngIf="bShowDragAndDrop" class="ttx">
      Drag & drop or upload assets below
    </div> -->
    <div *ngIf="bShowDragAndDrop" class="list" [ngClass]="{drp_hvr:bHover}" appDnd (onFileDropped)="onFileDropped($event)" (onFileOver)="onFileOver($event)" (onFileLeave)="onFileLeave($event)">
      <div class="ddmsg">
        <div class="ddmsg_l">
          <span class="ul" kzLib (click)="triggerMFile()"> Click to upload </span> or drag & drop multiple files
        </div>
        <div class="ddmsg_l st " [ngClass]="{st_hvr:bHover}">
          (png & jpeg)
        </div>
      </div>
    </div>
    <input #mfile class="hidden" (change)="selectedMultipleUploadImage($event)" type="file" multiple="multiple" />


    <img class="cancel" src="assets/images/close.svg" kzLib (click)="cancel()">

    <div *ngIf="files.length==0">

      <div *ngIf="!bShowDragAndDrop" class="list">
        <div class="abs_center ask_kz_code">
          Click
          <div class="checkNA" [ngClass]="{selected:bUsekzLib}" (click)="bUsekzLib=!bUsekzLib"></div>
          If your site includes kzLib to grant access to the rep after the user logs in
        </div>
      </div>

      <div *ngIf="bShowDragAndDrop" class="or">
        <div class="or_l"> </div>
        or
        <div class="or_l"> </div>
      </div>

      <div class="title font_sub_title line">
        <circle class="round" text="1"></circle>
        <div class="ttx"> Asset</div>
      </div>

      <div class="sel" kzLib (click)="showTypes($event)">
        <span class="hint" [ngClass]="{atype:asset_type.type }"> {{asset_desc}}</span>
        <img class="expand" [ngClass]="{colapse:bShowOpt}" src="assets/images/expand.svg">
      </div>
      <!-- <div class="title font_sub_title">
    {{asset_desc}}
  </div> -->


      <div class="title">
        <circle class="round" text="2"></circle>
        <div class="ttx"> Asset Name</div>
      </div>


      <input kzInput class="fback" type="name" placeholder="Name" [(ngModel)]="name">

      <div class="title font_sub_title">
        <circle class="round" text="3"></circle>
        <div class="ttx">Add Asset </div>
      </div>
      <div *ngIf="bShowClear" class="clear" kzLib (click)="clear()">
        Clear
      </div>
      <k-button *ngIf="bShowUpload &&!bShowSpinner" class="upload_btn" icon="upload.svg" background="transparent" kzLib (click)="triggerFile()"></k-button>
      <input #file class="hidden" (change)="selectedUploadImage($event)" type="file" name="image" ng2FileSelect [uploader]="uploader" [accept]="accept" />

      <input #link_fld kzInput *ngIf="!bUploading && !bShowURLS" class="fback" [ngClass]="{red:bBadLink}" type="name" [placeholder]="link_place_holder" [(ngModel)]="link" (ngModelChange)="linkChanged($event)">



      <div class="fline fline_s" *ngIf="bUploading">
        <img *ngIf="bShowUploaded" src="assets/images/ok.svg" class="fl_i fl_i_s" alt="">
        <img *ngIf="bError" src="assets/images/bad.svg" class="fl_i fl_i_s" alt="">
        <k-spinner *ngIf="bShowSpinner" class="fl_spin fl_spin_s"></k-spinner>
        <div class="fl_t fl_t_s">
          {{file_name}} <span *ngIf="!bError">uploading</span><span *ngIf="bError">,Error uploading</span>
        </div>
      </div>


      <div *ngIf="error" class="error">
        {{error}}
      </div>
      <div class="btns" [ngClass]="{showing_urls:bShowURLS}">
        <k-button min_width="40" min_height="40" [background]="save_back" color="white" class="abs_center" text="Save" kzLib (click)="save()"></k-button>
      </div>


      <iframe *ngIf="mat_url" id="sdk-iframe" class="hide" [src]="mat_url" allow="xr-spatial-tracking" allowFullScreen></iframe>

      <div *ngIf="bShowOpt" class="opt_container" kzLib (click)="stopProp($event)">
        <div class="opt" [ngClass]="{disabled:opt.disabled}" *ngFor="let opt of options;let i = index" kzLib (click)="selType(i)">
          <div class="opt_text"> {{opt.desc}}</div>
          <div class="check" [ngClass]="{selected:asset_type.type==opt.type, check_disabled:opt.disabled}"></div>
        </div>
      </div>

      <div class="opt_container urls_container" *ngIf="bShowURLS">
        <div class="">
          <img src="assets/images/close.svg" class="close" (click)="closeURLS()" alt="">

        </div>
        <div *ngFor="let url of urls;let i = index" class="opt" kzLib (click)="url_index=i">
          <div class="opt_text"> {{url}}</div>
          <div class="check" [ngClass]="{selected:url_index==i}"></div>
        </div>
      </div>
    </div>

    <div *ngIf="files.length>0">
      <div class="flist">
        <div class="fline" *ngFor="let file of files">
          <img *ngIf="file.status=='uploaded'" src="assets/images/ok.svg" class="fl_i" alt="">
          <img *ngIf="file.status=='error' || file.status=='canceled'" src="assets/images/bad.svg" class="fl_i" alt="">
          <k-spinner *ngIf="file.status=='waiting'" class="fl_spin"></k-spinner>
          <div class="fl_t">
            {{file.name}} {{file.status}}<span *ngIf="file.error">, {{file.error}}</span>
          </div>
        </div>
      </div>
      <div class="btns">
        <k-button *ngIf="uploading_number>0 && uploading_number == queued_files.length" min_width="40" min_height="40" background="#080808" color="white" class="abs_center" [text]="upload_btn_txt" kzLib (click)="cancel()"></k-button>
        <div class="stop" *ngIf="uploading_number>0 && uploading_number < queued_files.length" kzLib (click)="cancelUpload()">
          Stop uploading
        </div>
      </div>
    </div>
  </div>
</div>
