<div class="scrn" #scrn>
  <div class="abs_center out">

    <div class="line">

      <k-search class="search" type="name" placeholder="Search User" [(myModel)]="search" (myModelChange)="filterName($event)"></k-search>
    </div>

    <div class="line">

      <input class="num_users" type="text" placeholder="1" [(ngModel)]="num_users" (ngModelChange)="changeUsers($event)" [ngClass]="{min_usrs:bInvalidUsers}"><span class="fs14"> Max Users</span>
      <!-- <div class="status">
      Status
    </div>
    <img class="status_ex" [ngClass]="{close:bShowStatus}" src="assets/images/expand.svg" (click)="bShowStatus=!bShowStatus"> -->
    </div>
    <div class="gr_li">

    </div>

    <div class="users">
      <div class="" *ngFor="let user_key of users_keys, let i = index">
        <div class="line user_li">
          <img src="assets/images/call_user.svg" class="call" alt="" (click)="call(user_key)">
          <div class="u_name">
            {{this._account.users[user_key].name}}
          </div>
          <div class="roll">
            {{this._account.users[user_key].roll}}
          </div>
          <div class="det">
            View details
          </div>
          <img class="status_ex det_ex" [ngClass]="{close:i == expand_index}" src="assets/images/expand.svg" (click)="toggleDetails(i)">
        </div>

        <div class="details" *ngIf="i == expand_index">
          <!-- {{this._account.users[user_key]|json}} -->
          <div class="part">
            <div class="d_t">
              Phone number
            </div>
            <div class="d_d">
              {{phone}}
            </div>
            <div class="d_t">
              Last logged in
            </div>
            <div class="d_d">
              {{usage.on_line|date:'short'}}
            </div>
          </div>
          <div class="part">
            <div class="d_t">
              Email address
            </div>
            <div class="d_d">
              {{exp_user.email}}
            </div>
            <div class="d_t">
              Last logged out
            </div>
            <div class="d_d">
              {{usage.off_line|date:'short'}}
            </div>
          </div>
          <img *ngIf="picture" class="picture" [src]="picture" alt="">

        </div>
      </div>
    </div>

    <img class="close" src="assets/images/close.svg" (click)="cancel()">
  </div>
</div>
