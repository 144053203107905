import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { fitImgInTarget } from 'src/app/utils';
import { lastMsg } from 'src/app/wss/wss.selectors';
import { showAPI } from '../../pages.selectors';
import { SelAssetComponent } from '../sel-asset/sel-asset.component';

@Component({
  selector: 'asset',
  templateUrl: './asset.component.html',
  styleUrls: ['../sel-asset/sel-asset.component.scss', './asset.component.scss']
})
export class AssetComponent extends SelAssetComponent {
  @ViewChild('out') outDiv: ElementRef | undefined
  @ViewChild('iframe') iframe: ElementRef | undefined

  bShowAPI: boolean = false
  @Input() preview: boolean = false
  last_p2p_size_msg: any
  p2p_left: number = 0
  p2p_top: number = 0

  p2p_width: number = 0
  p2p_height: number = 0
  newWindow: any
  initSubClass() {
    console.log("asset component")
    this.subscriptions.push(this.pagesState.select(showAPI).subscribe((show: boolean) => {
      this.bShowAPI = show
    }))
  }
  ngAfterViewInit() {
    this.subscriptions.push(this.wssState.select(lastMsg).subscribe((msg: any | undefined) => {
      if (msg.p2p_size) {
        console.log("kzConn (asset1) <--" + JSON.stringify(msg) + new Date().toString())
        this.last_p2p_size_msg = msg
        this.onResize()
      } else if (msg.torep) {
        if (msg.torep.action == 'size') {
          console.log("kzConn (asset2)<--" + JSON.stringify(msg) + new Date().toString())
          this.last_p2p_size_msg = msg.torep
          this.onResize()
        }
      }
    }))
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?: any) { //aspect ratio is 1280, 720
    if (this.last_p2p_size_msg && this.outDiv) {
      if (this._asset.type == "site") {



        let rv = fitImgInTarget(this.last_p2p_size_msg.p2p_size.width,
          this.last_p2p_size_msg.p2p_size.height,
          this.outDiv.nativeElement.clientWidth,
          this.outDiv.nativeElement.clientHeight)
        this.p2p_top = rv.top
        this.p2p_left = rv.left
        this.p2p_width = rv.width
        this.p2p_height = rv.height

        if (this.iframe) {
          if (rv.top) {
            let scale = this.outDiv.nativeElement.clientWidth / this.last_p2p_size_msg.p2p_size.width
            this.iframe.nativeElement.style.transform = 'scale(' + scale + ')';
            this.iframe.nativeElement.style.transformOrigin = '0 0';
            this.p2p_width /= scale
            this.p2p_height /= scale
          } else {
            let scale = this.outDiv.nativeElement.clientHeight / this.last_p2p_size_msg.p2p_size.height
            this.iframe.nativeElement.style.transform = 'scale(' + scale + ')';
            this.iframe.nativeElement.style.transformOrigin = '0 0';
            this.p2p_width /= scale
            this.p2p_height /= scale

          }
        }
      } else if (this._asset.type == "on_board_p2p") {

        this.p2p_width = this.last_p2p_size_msg.p2p_size.width
        this.p2p_height = this.last_p2p_size_msg.p2p_size.height
        if (this.outDiv) {
          let left =
            (this.outDiv.nativeElement.clientWidth - this.p2p_width) / 2
          let top = (this.outDiv.nativeElement.clientHeight - this.p2p_height) / 2
          if (left < 0) {
            this.outDiv.nativeElement.style.overflowX = 'scroll';
            left = 0
          } else {
            this.outDiv.nativeElement.style.overflowX = 'none';
          }
          if (top < 0) {
            this.outDiv.nativeElement.style.overflowY = 'scroll';
            top = 0
          } else {
            this.outDiv.nativeElement.style.overflowY = 'none';
          }
          this.outDiv.nativeElement.style.position = "absolute"
          this.p2p_left = left
          this.p2p_top = top
        }
      }
    }
  }
  openInWnd() {
    if (this.opwn_msg.startsWith("The site")) {
      this.newWindow = window.open(this.opwn_site_in_wnd, '_blank', 'toolbar=yes,menubar=yes,scrollbars=yes,resizable=yes,width=' + this.p2p_width + ',height=' + this.p2p_height);
      this.opwn_msg = "You can interact with the user site in a separate window"
    }
  }
  cancel() {
    this.opwn_site_in_wnd = ""
  }

}
